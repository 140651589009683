import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
  query StudioGalleryMain {
    allCloudinaryMedia(filter: {public_id: {regex: "/studio/"}}) {
      edges {
        node {
          id
          public_id
          format
          resource_type
          version
          secure_url
        }
      }
    }
  }  
  
  `)
  return (
    <Layout>
      <SEO title="Galeri" description="" />
      <h1>Galeri</h1>
      <div className="container mt-100 ">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">Galeri</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                <span className="text-secondary font-weight-bold">
                  Carrara Pilates
              </span>
              'de konusunda uzman eğitmenlerle birlikte çalışın.
            </p>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row">
            <div>
              <div className="image-grid">
                {data.allCloudinaryMedia.edges.map(({ node }) => (
                  <div className="image-item">
                    <a href={node.secure_url} className='zoom'>
                      <img
                        loading='lazy'
                        className='gallery-image'
                        alt={`Carrara Pilates ${node.public_id}`}
                        srcSet={`https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_50,w_1080/v${node.version}/${node.public_id}.webp`}
                        src={`https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_50,w_1080/v${node.version}/${node.public_id}.webp`}
                      />
                    </a>
                  </div>

                ))}

              </div>

            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}


    // {
    //   allFile(
    //     filter: {
    //       name: { regex: "/gal/" }
    //       relativeDirectory: { eq: "gallery" }
    //     }
    //   ) {
    //     edges {
    //       node {
    //         childCloudinaryAsset  {
    //           fluid {
    //             ...CloudinaryAssetFluid
    //           }
    //         }
    //       }
    //     }
    //   }
    // }